import React, { useEffect } from 'react'
import { useApiGet } from '@papertrail/web3-utils'
import { useHistory } from 'react-router-dom'
import {
  Box,
  ContentHeader,
  ContentWrapper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ContentScrolling,
  TextSnippetOutlinedIcon,
  Loading
} from '@papertrail/styleguide'
import RecordState from './RecordState'
import NextCheck from './NextCheck'
import RecordName from './RecordName'
import SubHeader from './SubHeader'
import { Record } from 'src/types'

const Search = () => {
  const params = new URLSearchParams(location.search)
  const searchString = params.get('q')
  const [resultState, loadResults] = useApiGet(`/search/record`, (data) => data)
  const history = useHistory()

  useEffect(() => {
    loadResults({ q: searchString, mode: 'page' })
  }, [searchString])

  const goToResult = (result, type: string) => {
    switch (type) {
      case 'record':
        history.push(`/accounts/${result.account.data.id}/records/${result.id}`)
        break
      case 'folder':
        history.push(`/accounts/${result.account.data.id}/folders/${result.id}`)
        break
      case 'tag':
        history.push(`/accounts/${result.account.data.id}/folders?tag=${result.name}`)
        break
      case 'task':
        history.push(`/accounts/${result.account.data.id}/tasks/${result.id}`)
        break
    }
  }

  const handleSerialIdentifier = (record: Record, type: string) => {
    let cellValue: string
    if (!record.fields.data) {
      cellValue = ' '
    } else {
      const field = record.fields.data.find((field) => field.key === type)
      cellValue = field ? field.value : ' '
    }
    return cellValue
  }

  const showUploadImage = (record: Record) => {
    const upload = record.uploads.data.find((upload) => upload.default)
    if (upload && upload.view) {
      return `/api${upload.view}`
    }
  }

  return (
    <ContentWrapper>
      <ContentHeader>Universal Search</ContentHeader>
      {searchString && <SubHeader searchString={searchString} />}
      <ContentScrolling padding="0">
        {searchString &&
          resultState.isLoaded &&
          resultState.data &&
          resultState.data.data &&
          resultState.data.data.length > 0 && (
            <Box>
              <Table stickyHeader aria-label={'recordSearchTable'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Identifier</TableCell>
                    <TableCell>Serial</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Next Check</TableCell>
                  </TableRow>
                </TableHead>
                {resultState.isLoading && <Loading />}
                <TableBody>
                  {resultState.data.data.map((record) => {
                    return (
                      <TableRow
                        key={record.id}
                        sx={{
                          padding: '100px',
                          color: '#213854',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          goToResult(record, 'record')
                        }}>
                        <TableCell>
                          {record.uploads && (
                            <img style={{ height: '50px', maxWidth: '50px' }} src={showUploadImage(record)}></img>
                          )}
                          {!record.uploads ||
                            (record.uploads.length === 0 && (
                              <TextSnippetOutlinedIcon fontSize="large" color="action" />
                            ))}
                        </TableCell>
                        <RecordName record={record} />
                        <TableCell>{handleSerialIdentifier(record, 'identifier')}</TableCell>
                        <TableCell>{handleSerialIdentifier(record, 'serial')}</TableCell>
                        <RecordState
                          currentState={record.state.data}
                          log={record.log ? record.log.data : null}
                          user={record.log && record.log.data.user ? record.log.data.user : record.user}
                          loggedAt={record.log ? record.log.data.logged_at : record.created_at}
                        />
                        <NextCheck
                          nextCheckDate={record.next_check_at}
                          isDue={record.is_due}
                          isOverdue={record.is_overdue}
                        />
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          )}
      </ContentScrolling>
    </ContentWrapper>
  )
}

export default Search
